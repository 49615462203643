<template>
  <div>
    <van-nav-bar title="可更换产品" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <!-- <van-icon name="replay" size="18" /> -->
        <!-- <img class="qiehuan" src="../../assets/img/qiehuan.png" alt /> -->
      </template>
    </van-nav-bar>

    <div style="padding-top:46px;">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="0"
      >
        <div class="niandu">已为您优先展示爱车的原厂粘度和可选粘度</div>
        <div class="mian" v-for="(item,idx) in list" :key="idx" @click="toxiangqin(item.id)">
          <div class="left mian_a">
            <img class="order_img" :src="item.coverImg1" @error="imgError(item)" alt />
          </div>
          <div class="left mian_b">
            <div class="shuo_a">
              <span>{{item.brand}}</span>
              <span>/{{item.model}}</span>
              <span v-if="item.partName" style="margin-left: 5px;">{{item.partName}}</span>
              <span v-if="item.arr2" style="margin-left: 5px;">{{item.arr2}}</span>
              <span v-if="item.arr3" style="margin-left: 5px;">{{item.arr3}}</span>
              <span v-if="classify == '发动机油'" style="margin-left: 5px;">{{item.unit}}</span>
            </div>
            <div class="shuo_b">
              <span
                class="ciri"
                v-if="item.arr1"
                style="color:#d16e22;border-color:#d16e22;"
              >{{item.arr1}}</span>
              <!-- <span class="ciri">次日达</span> -->
              <!-- <span class="ciri">{{item.brand}}</span> -->
              <span class="ciri" v-if="item.arr3">{{item.arr3}}</span>
              <span class="ciri" v-if="item.unit">{{item.unit}}</span>
              <!-- <span class="ciri">4L</span> -->
            </div>
            <div class="jiage">
              <span class="danwei left">￥</span>
              <span class="mone left">{{item.activityPrice}}</span>
              <del class="mone2 left">￥{{item.sellPrice}}</del>
              <span class="qiangou right" @click.stop="xiangqing(item)">更换</span>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,
      size: 10,
      total: 0,
      classify: this.$route.query.classify,
      tires: this.$route.query.tires,
      unit: this.$route.query.unit,
      dataTips: this.$route.query.dataTips,
      keyWord: this.$route.query.keyWord,
      rule: this.$route.query.rule,
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia"))
    };
  },
  methods: {
    imgError(item) {
      item.coverImg1 = require("../../assets/img/mendian.png");
    },
    xiangqing(item) {
      item.rule = this.rule
      item.keyWord = this.keyWord
      console.log(item);
      sessionStorage.setItem("data", JSON.stringify(item));
      sessionStorage.setItem("isok", "true");

      this.$router.go(-1);
    },
    //跳转详情
    toxiangqin(id) {
      if (id != null) {
        this.$router.push({
          path: "change_details",
          query: {
            id: id
          }
        });
      }
    },
    onLoad() {
      const data = {
        carModel: this.chexin.carBrand,
        // carModel: "宝马",
        tires: this.tires,
        classify: this.classify,
        gid: this.shangjia.id,
        dataTips: this.dataTips,
        unit: this.unit,
        page: this.page,
        size: this.size,
        keyWord: this.keyWord,
        rule: this.rule
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .leimulist(data)
        .then(e => {
          loading.clear();
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          for (var i in rows) {
            if (rows[i].norm != null) {
              var norm = rows[i].norm.split("/");
              let arr = norm[1].split("");
              arr.splice(2, 0, "/");
              norm[1] = arr.join("");
              rows[i].arr1 = norm[0];
              rows[i].arr2 = norm[1];
              rows[i].arr3 = norm[2];
            }
          }
          console.log(rows);
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      this.page++;
      this.onLoad();
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.qiehuan {
  width: 13px;
  height: 13px;
  vertical-align: middle;
  margin-right: 4px;
}
.order_img {
  height: 85px;
  width: 85px;
}
.mian {
  padding: 10px 10px;
  overflow: hidden;
}
.mian_a {
  width: 85px;
}
.mian_b {
  font-size: 14px;
  margin-left: 10px;
  width: 255px;
  overflow: hidden;
}
.ciri {
  float: left;
  color: #2121c0;
  border: 1px solid #2121c0;
  height: 14px;
  line-height: 13px;
  font-size: 12px;
  transform: scale(0.83);
  padding: 0 1px;
  border-radius: 3px;
}
.shuo_a {
  height: 40px;
  font-weight: bold;
  line-height: 20px;
  overflow:hidden;
text-overflow:ellipsis;
display:-webkit-box;
-webkit-box-orient:vertical;
-webkit-line-clamp:2; 
}
.shuo_b {
  overflow: hidden;
  font-size: 10px;
  color: #666;
  line-height: 16px;
  margin-top: 9px;
}
.jiage {
  color: #a50e43;
  font-size: 16px;
  //   line-height: 22px;
  margin-top: 7px;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
  padding-bottom: 10px;
  position: relative;
}
.mone {
  //   font-weight: bold;
}
.niandu {
  line-height: 30px;
  background: #d2f4d8;
  font-size: 12px;
  text-align: center;
  color: #2c7038;
}
.mone2 {
  font-size: 12px;
  color: #666;
  vertical-align: bottom;
  margin-left: 5px;
}
.danwei {
  font-size: 12px;
  vertical-align: text-bottom;
}
.qiangou {
  color: #2121c0;
  font-size: 13px;
  vertical-align: text-bottom;
}
.shuo_c span:nth-of-type(n + 2) {
  margin-left: 5px;
}
.ciri:nth-of-type(n + 2) {
  margin-left: 5px;
}
</style>
